section.news-112 {
    position: relative;
    .news-item {
        .make-xs-column(12);
        .make-sm-column(4);
        .make-md-column(4);
        @media @small {
            &:last-child {
                display: none;
            }
        }
        @media @phone, @xsmall, @small {
            margin-bottom: 30px;
        }
        .newslink {
            height: auto;
            background: none;
            &:hover {
            }
            span {
                height: auto;
            }
        }
    }
    .verder {
        .font-size(18);
        display: block;
        font-weight: 600;
        margin-bottom: 30px;
        &:after {
            .icon;
            display: inline-block;
            width: 24px;
            line-height: 22px;
            height: 24px;
            content: '\e900';
            text-align: center;
            .lh-border-radius(50%);
            font-weight: 400;
            background-color: @color2;
            color: @white;
            margin-left: 12px;
            text-align: center;
            vertical-align: text-bottom;
        }
        &:hover {
            &:after {
                background-color: @color1;
                color: @white;
            }
        }
    }
}
