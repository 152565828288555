* {
    .lh-box-sizing(border-box);
}

html {
    font-size: 62.5%;
}

html,
body {
    margin: 0;
    padding: 0;
    border: 0;
    width: 100%;
    height: 100%;

}

body {
    font-family: @contentfont;
    color: @textcolor;
    .font-size(16);
    font-weight: 400;
    letter-spacing: 0.025em;
    line-height: 1.8;

    @media @phone, @xsmall {
        //.font-size(18);
    }
}

a {
    color: @color1;
    text-decoration: none;

    &:link {
        text-decoration: none;
        outline: 0;
    }

    &:visited {
        text-decoration: none;
        outline: 0;
    }

    &:active {
        text-decoration: none;
        outline: 0;
    }

    &:hover {
        text-decoration: underline;
        outline: 0;
    }
    .filelist & {

        &[href $='.pdf'],
        &[href $='.xls'],
        &[href $='.xlsx'],
        &[href $='.doc'],
        &[href $='.docx'],
        &[href $='.zip'],
        &[href $='.ppt'],
        &[href $='.pptx'] {
            &:not(.no-icon){
                &:before {
                    .icon;
                    display: inline-block;
                    margin-right: 5px;
                    font-size: 120%;
                    vertical-align: text-bottom;
                    content: "\e907";
                }
            }
        }
        &[href $='.pdf']:before { content: '\f1c1' !important;}
        &[href $='.xls']:before,
        &[href $='.xlsx']:before { content: '\f1c3' !important;}
        &[href $='.doc']:before,
        &[href $='.docx']:before { content: '\f1c2' !important;}
        &[href $='.ppt']:before,
        &[href $='.pptx']:before { content: '\f1c4' !important;}
        &[href $='.jpg']:before,
        &[href $='.png']:before { content: '\f1c5' !important;}
        &[href $='.zip']:before { content: '\f1c6' !important;}
    }
}

.header {
    background: @white;
    position: relative;
    z-index: 25;

    .clearfix();
    .container {
        padding: 0;
    }
    @media @phone, @xsmall {
    }

    .logo {
        position: relative;
        z-index: 20;
        .make-xs-column(8);
        .make-sm-column(5);
        .make-md-column(4);
        padding: 0;
		margin: 15px 0;
        a {
            display: block;
            height: 100%;
        }
        img {
            max-width: 100%;
        }

        @media @small {
            //margin-top: 25px;
        }

        @media @phone, @xsmall {
            margin: 10px 0;
            padding: 0 15px;
            height: auto;
        }
        .clearfix;
    }

    .navbar-header {
        .make-xs-column(4);
        margin: 10px 0;
        .navbar-toggle {
            margin-right: 0;
            border-radius: 0;
            border-color: @color1;
            .icon-bar {
                background-color: @color1;
            }
        }

    }
}

.languages {
    .make-sm-column(7);
    .make-md-column(8);
    text-align: right;

    @media @phone, @xsmall {
        display: none;
    }
    .lang-select {
        display: inline-block;
        vertical-align: top;
        margin-top: 10px;
        margin-bottom: 15px;

        @media @phone, @xsmall {
            margin-top: 10px;
        }
        @media @small {
            margin-bottom: 10px;
        }

        ul {
            .no-bullets;
            height: 20px;

            li {
                display: inline-block;
                height: 20px;
            }
        }

        a {
            display: inline-block;
            width: 30px;
            height: 20px;
            margin: 0 5px;
            background-repeat: no-repeat;
            vertical-align: top;


            &.flag_nl {
                background-image: url('../images/flag_nl.png');
            }
            &.flag_en {
                background-image: url('../images/flag_uk.png');
            }
            &.flag_fr {
                background-image: url('../images/flag_fr.png');
            }
            &.flag_de {
                background-image: url('../images/flag_de.png');
            }
            &.flag_es {
                background-image: url('../images/flag_es.png');
            }
            &.flag_sa {
                background-image: url('../images/flag_sa.png');
            }
            &.flag_cn {
                background-image: url('../images/flag_cn.png');
            }

            &:last-child {
                margin-right: 0;
            }

            .lh-opacity(0.5);

            //filter: url("data:image/svg+xml;utf8,&lt;svg xmlns=\'http://www.w3.org/2000/svg\'&gt;&lt;filter id=\'grayscale\'&gt;&lt;feColorMatrix type=\'matrix\' values=\'0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0\'/&gt;&lt;/filter&gt;&lt;/svg&gt;#grayscale"); /* Firefox 10+, Firefox on Android */
            filter: gray; /* IE6-9 */
            -webkit-filter: grayscale(100%); /* Chrome 19+, Safari 6+, Safari 6+ iOS */

            &.active, &:hover {
                .lh-opacity(1);
                //filter: url("data:image/svg+xml;utf8,&lt;svg xmlns=\'http://www.w3.org/2000/svg\'&gt;&lt;filter id=\'grayscale\'&gt;&lt;feColorMatrix type=\'matrix\' values=\'1 0 0 0 0, 0 1 0 0 0, 0 0 1 0 0, 0 0 0 1 0\'/&gt;&lt;/filter&gt;&lt;/svg&gt;#grayscale");
                -webkit-filter: grayscale(0%);
            }
        }
    }
}

.header-options {
    @media @phone, @xsmall {
        display: none;
    }
    .font-size(16);



    .make-sm-column(7);
    .make-md-column(8);

	margin: 0;
	
    text-align: right;
    a {
        color: @grey;
        display: inline-block;
        a + a {
            margin-left: 5px;
        }
        &:after {
            .icon;
            display: inline-block;
            width: 32px;
            height: 32px;
            text-align: center;
            line-height: 32px;
        }
        &.email:after {
            content: '\f0e0';
        }
        &.phone:after {
            content: '\f095';
        }
        &.marker:after {
            content: '\f041';
        }
    }
}




.searchform {

    .header-options & {
        display: inline-block;
        vertical-align: bottom;
        @media @phone, @xsmall {
            display: none;
        }
    }
    input {
        float: left;
        width: 200px;
        padding: 0 10px;
        border: 1px solid @grey;
        border-right: none;
        background: @white;
        height: 35px;
        vertical-align: middle;
        -webkit-appearance: none;
        .lh-border-radius(0);
    }
    button {
        float: left;
        width: 30px;
        text-align: center !important;
        border: 1px solid @grey;
        border-left: none;
        background: @white;
        height: 35px;
        vertical-align: middle;
        line-height: 1;
        -webkit-appearance: none;
        padding-top: 5px;
        color: @grey;
        &:after {
            .icon;
            content: '\f002';
        }
    }
}

.searchresult {
    .make-md-column(10);
    padding: 0;
    display: block;
    margin-bottom: 10px;

    h3 {
        .font-size(20) !important;
        margin: 0 !important;
    }
    p {

    }

    &:hover {
        color: @color1 !important;
        text-decoration: none !important;

        h3 {
            color: @color1 !important;
        }
    }
}


.mainmenu {
    border-bottom: 1px solid @color2;
    position: relative;
    z-index: 10;

    .container {
        padding: 0;
    }
    @media @large {
        height: 50px;

    }
    @media @medium {
        height: 50px;

    }
    @media @small {
        height: 50px;

    }

    @media @phone, @xsmall {
        .container {
            padding: 0;

        }
    }

    .navbar-header {
        background-color: @color1;
        margin: 0;

        .menu-header {
            @media @small, @medium, @large {
                display: none;
            }

            float: left;
            margin-left: 15px;
            max-width: 80%;

            a {
                display: inline-block;
                .font-size(16);
                line-height: 1;
                color: @color2;
                font-weight: bold;
                padding: 15px 0;
            }
        }

        .navbar-toggle {
            background-color: @color2;
            margin-right: 15px;

            .icon-bar {
                background-color: @color1;
            }
        }
    }

    .menu {
        position: relative;
        z-index: 2;
        padding: 0;
        .lh-box-shadow(none);

        @media @small, @medium, @large {
        }

        @media @phone, @xsmall {
            padding: 5px 15px;
            background: @white;
            //margin: 0;
        }
        > ul {
            width: 100%;
            .no-bullets;
            .clearfix;
            text-align: left;
            @media @phone, @xsmall {
                padding-bottom: 15px;
            }

            > li {
                position: relative;
                display: inline-block;
                &.mobiel {
                    display: none;
                }
                @media @phone, @xsmall {
                    padding: 0;
                    display: block;
                    width: 100%;
                    &.mobiel {
                        display: block;
                        padding: 6px 15px;
                        a {
                            &.flag_nl {
                                width: 30px;
                                height: 20px;
                                margin: 0 5px;
                                background-repeat: no-repeat;
                                background-image: url('../images/flag_nl.png');
                                .lh-opacity(0.5);

                                //filter: url("data:image/svg+xml;utf8,&lt;svg xmlns=\'http://www.w3.org/2000/svg\'&gt;&lt;filter id=\'grayscale\'&gt;&lt;feColorMatrix type=\'matrix\' values=\'0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0\'/&gt;&lt;/filter&gt;&lt;/svg&gt;#grayscale"); /* Firefox 10+, Firefox on Android */
                                filter: gray; /* IE6-9 */
                                -webkit-filter: grayscale(100%); /* Chrome 19+, Safari 6+, Safari 6+ iOS */
                            }
                            &.flag_en {
                                width: 30px;
                                height: 20px;
                                margin: 0 5px;
                                background-repeat: no-repeat;
                                background-image: url('../images/flag_uk.png');
                                .lh-opacity(0.5);

                                //filter: url("data:image/svg+xml;utf8,&lt;svg xmlns=\'http://www.w3.org/2000/svg\'&gt;&lt;filter id=\'grayscale\'&gt;&lt;feColorMatrix type=\'matrix\' values=\'0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0\'/&gt;&lt;/filter&gt;&lt;/svg&gt;#grayscale"); /* Firefox 10+, Firefox on Android */
                                filter: gray; /* IE6-9 */
                                -webkit-filter: grayscale(100%); /* Chrome 19+, Safari 6+, Safari 6+ iOS */
                            }
                            &.flag_de {
                                width: 30px;
                                height: 20px;
                                margin: 0 5px;
                                background-repeat: no-repeat;

                                background-image: url('../images/flag_de.png');
                                .lh-opacity(0.5);

                                //filter: url("data:image/svg+xml;utf8,&lt;svg xmlns=\'http://www.w3.org/2000/svg\'&gt;&lt;filter id=\'grayscale\'&gt;&lt;feColorMatrix type=\'matrix\' values=\'0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0\'/&gt;&lt;/filter&gt;&lt;/svg&gt;#grayscale"); /* Firefox 10+, Firefox on Android */
                                filter: gray; /* IE6-9 */
                                -webkit-filter: grayscale(100%); /* Chrome 19+, Safari 6+, Safari 6+ iOS */
                            }



                            &.active, &:hover {
                                .lh-opacity(1);
                                //filter: url("data:image/svg+xml;utf8,&lt;svg xmlns=\'http://www.w3.org/2000/svg\'&gt;&lt;filter id=\'grayscale\'&gt;&lt;feColorMatrix type=\'matrix\' values=\'1 0 0 0 0, 0 1 0 0 0, 0 0 1 0 0, 0 0 0 1 0\'/&gt;&lt;/filter&gt;&lt;/svg&gt;#grayscale");
                                -webkit-filter: grayscale(0%);
                            }

                            vertical-align: middle;
                            padding: 0;
                            display: inline-block;
                            &:after {
                                .icon;
                                display: inline-block;
                                width: 32px;
                                height: 32px;
                                text-align: center;
                                line-height: 32px;
                                .font-size(24);
                            }
                            &.email:after {
                                content: '\f0e0';
                            }
                            &.phone:after {
                                content: '\f095';
                            }
                            &.marker:after {
                                content: '\f041';
                            }
                        }

                    }
                }

                a, span {
                    display: block;
                    color: @color1;
                    .font-size(16);
                    font-weight: normal;


                    //.lh-transition(background 0.15s ease);

                    @media @phone, @xsmall {
                        padding: 5px 15px;
                        height: auto;
                        text-align: left;
                        .font-size(15);
                    }

                    @media @small, @medium, @large {
                        line-height: 1.1;
                        text-align: center;
                    }

                    @media @small {
                        .font-size(16);
                        padding: 16px 8px 16px;
                        max-height: 57px;
                    }

                    @media @medium {
                        padding: 16px 12px ;
                        max-height: 67px;
                    }

                    @media @large {
                        max-height: 50px;
                        padding: 20px 35px;
                    }

                }

                &:first-child {

                }

                &:hover {
                    > a, > span {
                        text-decoration: none;
                        color: @color2;
                    }

                    ul {
                        display: block;
                        list-style-image: url(data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7);
                    }
                }



                @media @small, @medium, @large {
                    display: inline-block;

                    > a {
                        //letter-spacing: 0.25px;
                    }

                    &.active {
                        > a {
                            font-weight: 600;
                        }
                    }

                }

                ul {
                    .no-bullets;
                    //.lh-box-shadow(2px 2px 4px 0 rgba(0,0,0,0.2));
                    text-align: left;
                    //background-color: fade(@color1, 80%);
                    background: @color2;
                    padding-bottom: 5px;
                    @media @small, @medium, @large {
                        display: none;

                        position: absolute;
                        left: 0;
                        top: 100%;
                        z-index: 15;
                        //background: @white;
                        min-width: 300px;
                        //border: 1px solid #bbb;
                        border-top: none;
                        //padding: 10px 0 0 0;

                        li {

                            a {
                                display: block;
                                text-align: left;
                                color: @white;
                                background: @color2;
                                //background: fade(@white, 90%);
                                .lh-transition(background 0.2s ease);
                                padding-top: 8px;
                                padding-bottom: 8px;
                                text-transform: none;

                                &:hover {
                                    color: @white;
                                    background-color: @color1;
                                    text-decoration: none;
                                }
                            }
                        }
                    }

                    @media @phone, @xsmall {
                        margin: 0 0 15px 0;
                        background: none;
                        li {
                            a {
                                display: block;
                                .font-size(14);
                                line-height: 1.3;
                                text-transform: none;
                                padding: 5px 15px 5px 30px;
                            }
                        }
                    }

                }

                &:last-child {
                    ul {
                        left: auto;
                        right: 0;
                    }
                }

            }
        }
    }
}


.top-carrousel {
    position: relative;
    width: 100%;
    z-index: 0;
    &:before {
        z-index:1;
        position: absolute;
        bottom: 0;
        left: 0;
        top: 0;
        background-image: url(../images/overlay-nl.png);
        background-repeat: no-repeat;
        background-position: bottom left;
        width: 20%;
        background-size: contain;
        content: '';
    }
    &:after {
        z-index:1;
        position: absolute;
        bottom: 0;
        right: 0;
        top: 0;
        background-image: url(../images/overlay-de.png);
        background-repeat: no-repeat;
        background-position: top right;
        width: 20%;
        background-size: contain;
        content: '';
    }

    @media @medium, @large {
		height: 400px;
    }
    @media @small {
        height: 300px;
    }
    @media @phone, @xsmall {
        height: 280px;
    }

    .bx-wrapper,
    .bx-viewport {
        display: block;
        height: 100%;
    }

    ul {
        .no-bullets;
        height: 100%;
        li {

            height: 100%;
            background-repeat: no-repeat;
            background-position: center;
            background-size: cover;

            .container {
                position: relative;
                height: 100%;
                z-index: 2;
                padding: 0;
            }

            a {
                display: block;
                height: 100%;
                span:after {
                    display: inline-block;
                    content: 'lees meer';
                    .font-size(16);
                    color: @color1;
                    padding: 6px 15px;
                    background-color: fade(@white, 50%);
                    float: right;
                    margin-bottom: 10px;

                    @media @phone, @xsmall {
                        .font-size(16);
                        padding: 7px 30px;


                    }
                }

                &:hover {
                    span {
                        &:after {
                            background-color: fade(@color2, 50%);
                            color: @white;
                        }
                    }
                }
            }


            span {
                display: block;
                position: absolute;
                top: 20%;
                right: 100px;
                padding: 20px;
                max-width: 60%;
                text-align: center;
                background-color: fade(@white, 50%);
                @media @phone, @xsmall {
                    right: 15px;
                    left: 15px;
                    width: auto;
                    max-width: 100%;
                    padding: 5px 12px;
                    top: auto;
                    bottom: 15px;
                }
                h4 {
                    font-weight: normal;
                    color: @textcolor;
                    font-family: @subtitlefont;
                    font-weight: 300;
                    line-height: 1.4;
                    @media @small, @medium, @large {
                        .font-size(38);
                    }

                    @media @phone, @xsmall {
                        .font-size(22);
                    }
                }

                h6 {
                    display: none;
                }

            }
        }
    }
    .bx-controls {
        //position: relative;
        z-index: 20;

        @media @phone, @xsmall, @small {
            display: none;
        }

        .bx-controls-direction {
            a {
                background: fade(@white, 40%);
                text-indent: 0;
                width: 60px;
                height: 60px;
                margin-top: -25px;
                padding-top: 15px;
                text-align: center;
                text-decoration: none;
                &:before {
                    .icon;
                    .font-size(30);
                    color: @grey;

                }

                &.bx-prev {
                    left: 40px;

                    &:before {
                        content: '\e901';
                    }
                }
                &.bx-next {
                    right: 40px;

                    &:before {
                        content: '\e900';
                    }
                }

                &:hover {
                    background: fade(@color1, 70%);
                    &:before {
                        color: @white;
                    }

                }
                @media @small, @phone, @xsmall {
                    background: fade(@black, 30%);
                    &.bx-prev {
                        left: 0;
                    }
                    &.bx-next {
                        right: 0;
                    }

                }
            }

        }
    }
}
.header-image {

    //.container;
    background-position: center;
    background-size: cover;

    position: relative;
    width: 100%;
    z-index: 0;


    @media @medium, @large {
        height: 300px;
    }
    @media @small {
        height: 240px;

    }
    @media @phone, @xsmall {
        height: 150px;
    }
}

.bg-image {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 1;
    width: 65%;
    height: 100%;
    background-position: left center;
    background-repeat: no-repeat;
    background-size: cover;

}

.pageheader {
    position: relative;
    z-index: 9;

   hgroup {
       position: relative;
       z-index: 2;
        display: none;
        h1 {
            &:extend(.zerniq_wysiwyg h1 all);
            //text-align: center;
        }
        h4 {
            &:extend(.zerniq_wysiwyg h3 all);
        }


    }
}


.breadcrumbs {
    clear: both;
    position: relative;
    z-index: 3;
    background: @white;
    .font-size(16);
    color: #ababab;
    font-family: @contentfont;
    text-transform: lowercase;

    @media @small, @medium, @large {
        margin-top: 10px;

        .header-image + &,
        .top-carrousel + & {
            margin-top: 0;
        }
    }

    @media @phone, @xsmall {
        .font-size(14);
    }


    .container {
        padding-top: 7px;
        padding-bottom: 7px;
        //padding-left: 0;
        //padding-right: 0;
        background: transparent;
        margin-bottom: 15px;
    }
    ul, ol {
        .no-bullets;

        li {
            display: inline-block;

            a {
                color: #ababab;
                &:hover {
                    color: @color1;
                    text-decoration: none;
                }
            }

            &:after {
                content: '|';
                display: inline-block;
                padding: 0 2px 0 5px;
            }

            &:last-child {
                color: #ababab;
                a {
                    color: #ababab;
                }
                &:after {
                    content: '';
                }
            }
        }
    }

    .sticky-wrapper + & {
        padding-top: 15px;
    }
}

.sidemenu {
    @media @phone, @xsmall {
        display: none;
    }

    ul {
        .no-bullets;
        li {
            border-bottom: 1px solid fade(@color1, 60%);
            a {
                position: relative;
                display: block;
                .font-size(18);
                font-weight: 300 !important;
                line-height: 1.1;
                color: @black !important;
                padding: 15px 8px 8px 8px !important;

                &:hover {
                    text-decoration: none !important;
                    background-color: fade(@color1, 20%);
                }
            }

            &.active {
                border-bottom: 0;
                > a {
                    background-color: fade(@color1, 83%);
                    color: @white !important;
                }
            }

            ul {
                border-top: 1px solid fade(@grey, 50%);
                li {
                    padding-left: 20px;
                    border-bottom: 1px solid fade(@grey, 50%);

                    &:last-child {
                        border: none;
                    }
                }
            }
        }
    }

    .page-home & {
        ul {
            li {
                a {

                }
            }
        }
    }
}


.content {
    clear: both;
    position: relative;
    z-index: 2;
    min-height: 200px;


    > section {
        padding-top: 25px;
        padding-bottom: 25px;

        p:last-child {
            margin-bottom: 0;
        }

        /*&:first-child {
            @media @small, @medium, @large {
                padding-top: 50px;
            }

            @media @phone, @xsmall {
                padding-top: 30px;
            }
        }*/

        @media @phone, @xsmall {
            //margin: 0 15px;
        }

        .block {
            .clearfix;
            //min-height: 50px;
            &:extend(.zerniq_wysiwyg all);

        }
        &:not(.wide){
            > .block {
                .container;
                padding: 0;
            }
        }
        &.wide {
            > .block {
                .container-fluid;
                padding: 0;
            }
        }


        &.first-block {
            .col-left {
                .make-sm-column(4);
                .make-md-column(3);
            }
            .col-right {
                .make-sm-column(8);
                .make-md-column(9);
            }
        }

        &.bgcolor-1 {
            background: @color1;
            *, a, .block-title {
                color: @white;
            }
            a:hover {
                color: @color2;
            }
            h1,h2,h3,h4 {
                color: @white;
            }
            ul {
                li {
                    &:before {
                        color: @white;
                    }
                }
            }

        }

        &.bgcolor-2 {
            background: rgba(183, 210, 240, 0.49);
        }

        &.bgcolor-3 {
            background: #ececec;
        }
        &.bgcolor-5 {
            display: none;
            margin-top: 30px;
            margin-bottom: 30px;
            .block {
                //&:extend(.zerniq_wysiwyg all);
                @media @phone, @xsmall {
                    padding: 0px 15px !important;
                }
            }
            .col1 {
                border: 1px solid @color2;
                padding: 20px 100px !important;
                .make-sm-column(8);
                .col-centered;
                @media @medium {
                    padding: 20px 70px !important;
                    width: 75% !important;
                }
                @media @small {
                    padding: 20px 50px !important;
                    width: 83% !important;
                }
                @media @phone, @xsmall {
                    padding: 20px 20px !important;
                }
                text-align: center;
                color: @color1;
                a {
                    display: inline-block;
                    width: 50%;
                    float: left;
                    @media @phone, @xsmall {
                        width: 100%;
                    }
                }
            }
        }


        // Homepage exceptions

        /*&:first-child {
            .page-home & {
                padding-top: 0;
                background: @grey;
                .block * {
                    color: @white !important;
                }
            }
        }*/

        &:not([class^='content-1']) .block-title {
            .make-sm-column(12);
        }
    }


    //.banners-120, .banners-121, .banners-122, .banners-123 {

    //}


    .image-block {
        min-height: 100px !important;
        background-position: center;
        background-size: cover;
    }
}

.addthis {
    padding-top: 20px !important;
    padding-bottom: 20px !important;
    text-align: center;

}

.fit-vids-style {
    max-height: 0;
}

.newslink {
    .lh-transition(all 0.15s ease);
    .was-news-112 & {
        .lh-box-shadow(0px 2px 5px 0px fade(@darkgrey, 50%));
        padding: 20px 15px;
        background-color: @white !important;
        div.fig,
        figure {
            display: none;
        }
        span {
            padding-bottom: 60px;
            time {
                display: none !important;
            }
        }
        &:hover {
            .lh-box-shadow(0px 2px 10px 3px rgba(0, 0, 0, 0.3));
        }

    }
    .news-112 &,
    .news-110 & {
        div.fig {
            @media @small, @medium, @large {
                height: 220px;
            }

            @media @phone, @xsmall {
                height: 120px;
            }
            .lh-box-shadow(0 13px 11px -8px fade(@darkgrey, 50%));
            overflow: hidden;
            margin-bottom: 10px;
        }
        time {
            margin-bottom: 5px;
        }
        figure {
            @media @small, @medium, @large {
                height: 220px;
            }

            @media @phone, @xsmall {
                height: 120px;
            }
            position: relative;
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center center;
            .lh-transition(all 0.2s ease);

        }
        &:hover {
            figure {
                transform: scale(1.1);
            }
        }

        }
    padding-bottom: 60px;
    display: block;
    span {

        position: relative;

        display: block;
        h3 {
            color: @color1 !important;
            .font-size(20) !important;
            margin: 0 0 10px 0 !important;
            text-transform: none !important;
        }
        p {
            font-weight: normal !important;
            margin: 0 !important;
            max-height: 81px;
            overflow: hidden;
            color: @textcolor;
        }

    }
    &:after {
        display: inline-block;
        text-align: center;
        position: absolute;
        left: 50%;
        bottom: 22px;
        width: 32px;
        height: 32px;
        margin-left: -16px;
        margin-top: 10px;
        .icon;
        line-height: 32px;
        content: '\e900';
        color: @white;
        background-color: @color2;
        .lh-border-radius(20);
        .font-size(20) !important;
        font-weight: normal !important;
    }

    &:hover {
        text-decoration: none !important;
        h3, p {
            color: @color1 !important;
        }
        &:after {
            background: @color1;
        }
    }
}
/*.banner,
.newslink {
    @media @large {
        height: 520px;
    }
    @media @medium {
        height: 200px;

    }

    @media @small {
        height: 260px;
    }

    @media @phone, @xsmall {
        height: 220px;
        margin: 0 0 @grid-gutter-width 0;
    }
}*/
.news-single-item {
    &:extend(.content-10 all);
    .block {
        padding: 0 !important;
    }
    .fluid-width-video-wrapper {
        margin-bottom: 10px;
    }

    nav {
        .make-sm-column(4);

        @media @phone, @xsmall {
            display: none;
        }
        .mc-box {
            margin: 30px -15px;
            padding: 15px;
            background-color: @lightblue;
            h3 {
                color: @darkblue;
            }
            p, label {
                color: @white;
            }
            a {
                color: @darkblue;
                &:hover {
                    color: @black;
                }
            }
        }
        .terug {
            .font-size(18);
            display: block;
            font-weight: 600;
            margin-bottom: 30px;
            &:before {
                .icon;
                display: inline-block;
                width: 24px;
                line-height: 22px;
                height: 24px;
                content: '\e901';
                text-align: center;
                .lh-border-radius(50%);
                font-weight: 400;
                background-color: @color2;
                color: @white;
                margin-right: 12px;
                text-align: center;
                vertical-align: text-bottom;
            }
            &:hover {
                &:before {
                    background-color: @color1;
                    color: @white;
                }
            }
        }
        ul {
            .no-bullets;
            margin: 0 !important;
            li {
                position: relative;
                &:after {
                    position: absolute;;
                    bottom: 0px;
                    left: 0;
                    width: 50%;
                    border-top: 1px solid @grey;
                    content: '';
                }
                //margin-bottom: 10px;
                a {
                    font-weight: 400;
                    text-align: left !important;
                    line-height: 1.1 !important;
                    color: @color1 !important;
                    &:hover {
                        color: @color2 !important;
                        text-decoration: none !important;
                    }
                    time {
                        color: @text-color !important;
                        padding-bottom: 5px;
                    }
                }
                &:before {

                }
            }
        }
    }
    article {
        .make-sm-column(8);
        @media @phone, @xsmall {
            padding: 0 15px;
        }
        .intro, figure {
            margin-bottom: 15px;
        }
    }
}
.deurmat {
    position: relative;
    z-index: 2;
    background: @color1;
    color: @white;

    .container {
        padding-top: 30px;
        padding-bottom: 15px;
    }

    a {
        color: @white;
        &:hover {
            text-decoration: none;
            color: @color2;
        }
    }

    .column {
        .make-sm-column(4);

        @media @phone, @xsmall {
            margin-bottom: 15px;
        }
    }

    p {
        margin: 0 0 20px 0;
    }

    p + ul {
        margin-top: -20px;
    }

    ul {
        .no-bullets;
        //margin: 0 0 20px 0;
        list-style-type: none;

        li {
            position: relative;
            padding-left: 8px;
            /*&:before {
                position: absolute;
                left: 0;
                top: 0;
                content: "-\0020";
            }*/
        }
    }

}
.footer {
    position: relative;
    z-index: 2;
    padding: 8px 0;
    background: @darkblue;

    .block {
       .clearfix;
        &:extend(.zerniq_wysiwyg all);
       .container;
        padding: 0;
    }

    .font-size(14);

    .copyright {
        .make-sm-column(6);
        color: @white;
        text-align: right;
    }
    .links {
        .make-sm-column(6);
        color: @white;
        p {
            margin: 0;
        }
        a {
            color: @white;
            &:hover {
                color: @color2;
            }
        }
        a + a {
            margin-left: 20px;

        }
        ul {
            .no-bullets;
            li {
                display: inline-block;
                margin-left: 12px;
            }
        }
    }

}


#mc-footer, #mc-sidebar, #mc-content {
    button {
        &:extend(.zerniq_wysiwyg a.knop all);
        border: 1px solid @blue;
        background-color: @blue;
        color: @white;
        &:hover {
            background-color: @darkblue;
            border-color: @darkblue;
        }
    }
}

section.content-mc {
    &:extend(.content-21 all);
    .col1 {
        .make-md-column-offset(1);
        .make-sm-column(6);
        .make-md-column(4);
        border: 1px solid @color2;
        padding: 20px 30px !important;
        @media @medium {
            padding: 20px 30px !important;
            width: 75% !important;
        }
        @media @small {
            padding: 20px 50px !important;
            width: 83% !important;
        }
        @media @phone, @xsmall {
            padding: 20px 20px !important;
        }
        text-align: center;
        color: @color1;
        a {
            display: inline-block;

            @media @phone, @xsmall {
                width: 100%;
            }
        }

    }
    .col2 {
        .make-md-column-offset(2);
        .make-sm-column(6);
        .make-sm-column(4);
        padding: 15px;
        background-color: @lightblue;
        h3 {
            color: @darkblue;
        }
        p, label {
            color: @white;
        }
        a {
            color: @darkblue;
            &:hover {
                color: @black;
            }
        }

    }
}