// --------------------------------------------------
// Global settings
// --------------------------------------------------

// Colors
@black: #000;
@white: #FFF;

@blue: #053874;
@darkblue: #03244c;
@lightblue: #829cba;

@red: #e10f13;
@darkred: #920a0c;
@lightred: #e73f42;

@grey: #b4b4b4;
@mediumgrey: #53585f;
@darkgrey: #282e37;
@lightgrey: #e3e6e5;

@banner-text: @black;

@color1: @blue;
@color2: @red;
@contrast1: @grey;
@contrast2: @darkblue;
@textcolor: @darkgrey;
@titlecolor: @darkblue;


// mix with white: tint()
// mix with black: shade()


// Typography
@contentfont: 'Open Sans', Arial, Helvetica, sans-serif;
@titlefont: @contentfont;
@subtitlefont: @titlefont;


// Templates
@leegheight: 100px; //400px;

// Breakpoints
/* Extra small vertical devices (phones, less than 480px) */
@phone:                     ~"only screen and (max-width: @{screen-xs-min})";

/* Extra small devices (horizontal phones, less than 768px) */
@xsmall:                    ~"only screen and (min-width: @{screen-xs-min}) and (max-width: @{screen-xs-max})";

/* Small devices (tablets, 768px and up) */
@small:                     ~"only screen and (min-width: @{screen-sm-min}) and (max-width: @{screen-sm-max})";

/* Medium devices (desktops, 992px and up) */
@medium:                    ~"only screen and (min-width: @{screen-md-min}) and (max-width: @{screen-md-max})";

/* Large devices (large desktops, 1200px and up) */
@large:                     ~"only screen and (min-width: @{screen-lg-min})";

/* Higher pixel density screens */
@retina:                    ~"only screen and (-webkit-min-device-pixel-ratio: 1.5), only screen and (min-resolution: 144dpi)";

@mobile: @phone, @xsmall;
@nonmobile: @small, @medium, @large;

