section.contact-132 {
    //padding-top: 0;
    //padding-bottom: 0;

    .col1 {
        .make-sm-column(8);
        .make-md-column(6);

    }
    .col2 {
        .make-sm-column(8);
        .make-md-column(6);

    }



}
