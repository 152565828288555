.banner {
    display: block;
    text-align: left;
    // background-color: @color1;
    .clearfix;
	position: relative;
	h3 {
		margin-top: 15px !important;
		margin-bottom: 0 !important;
		text-align: center;
		color: @blue !important;
		font-weight:600;
		font-size: 20px !important;
		// text-shadow: 2px 3px 3px @darkgrey;
		@media @phone, @xsmall {
			// top: 50px;
			font-size: 20px !important;
		}
		word-wrap:break-word;
	}
	p {
		text-align: center;
	}
    figure {
		.lh-transition(all 0.3s ease);
		.lh-box-shadow(0 13px 11px -8px fade(@darkgrey, 50%));
		@media @small, @medium, @large {
			height: 220px;
		}

		@media @phone, @xsmall {
			height: 160px;
		}



		overflow: hidden;

		@media @small, @medium, @large {
			height: 220px;
		}

		@media @phone, @xsmall {
			height: 160px;
		}
		background-size: cover;
		background-repeat: no-repeat;
		background-position: center center;
		.lh-transition(all 0.2s ease);
    }

    &.active, &:hover {
        text-decoration: none !important;
		// background-color: @darkgrey;
		figure {
			transform: scale(1.1);
		}
    }

}
